import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Config from '../../Config';
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Drupal2Json from "../../Util/Drupal2Json";
import GroupStatsChips from "../../Views/GroupDetails/GroupStatsChips";

class Homepage extends Component {

    render() {
        if (!this.props.group) return 'loading...';

        const json = new Drupal2Json(this.props.group);

        return (
            <Grid
                container
                spacing={0}
                style={{padding: 8}}
                direction='column'
                justifyContent='space-evenly'
                alignContent='space-between'
                alignItems='center'
            >
                <div style={{width: '100%', textAlign: 'center'}}>

                    <img style={{width: '100%', maxWidth: 400, marginBottom: 10}}
                         src={json.get('field_cover', 'url')}
                         alt={json.get('field_cover', 'alt')}/>

                    <Typography variant='h1'>{Config.name}</Typography>
                    <Typography variant='h6'>The only Way Hip-Hop albums should be reviewed</Typography>

                    <Grid item style={{textAlign: 'center', margin: '40px 0'}}>
                        <Button component={Link} to={'/group/54/playlists'} color='primary' variant='contained' size={'large'} style={{margin: 15}}>Rate Albums</Button>
                    </Grid>

                    <GroupStatsChips {...this.props} />

                    {this.props.uid > 0 ?
                        <Grid item style={{position: 'absolute', bottom: 5, right: 10}}>
                            <Button component={Link} to={`/users/${this.props.uid}`} variant={'contained'}
                                    size={'small'} color='secondary' style={{margin: 15}}>My Account</Button>
                           {this.props.member_id && <Button component={Link} to={`/group/54/members/${this.props.member_id}`} variant={'contained'}
                                    size={'small'} color='secondary' style={{margin: 15}}>My Membership</Button>}
                        </Grid>
                        :
                        <Grid item style={{position: 'absolute', bottom: 5, right: 10}}>
                            <Button component={Link} to={'/login'} variant={'contained'} color='secondary'
                                    size={'small'} style={{margin: 15}}>Sign In</Button>
                            <Button component={Link} to={'/group/54/signup'} variant={'contained'} size={'small'}
                                    color='secondary' style={{margin: 15}}>Sign Up</Button>
                        </Grid>
                    }


                </div>
            </Grid>
        );
    }
}


const styles = theme => ({
    logo: {
        width: '80%',
        maxWidth: 300,
        margin: '0 auto'
    },
    vidBackground: {
        zIndex: -1,
        position: 'fixed',
        right: 0,
        left: 0,
        top: 0,
        opacity: .2,
        minWidth: '100%',
        minHeight: '100%'
    },
    card: {
        width: '100%',
        maxWidth: 960,
        justifyContent: 'center',
        marginBottom: 15,
    },
    list: {
        marginTop: 10,
        marginBottom: 10,
    },
    paperBg: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: 8
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    content: {
        padding: theme.spacing(1)
    },
    actions: {
        padding: '3px 6px 3px 6px',
        margin: '10px auto 10px auto',
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between'
    },
});

Homepage.defaultProps = {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
};

Homepage.propTypes = {
    windowHeight: PropTypes.number,
    windowWidth: PropTypes.number
};


const mapStateToProps = (state) => {
    let newState = {};
    if (state.auth.me && state.auth.me.profile) {
        newState.uid = state.auth.me.profile.uid[0].value;
    }
    if (state.auth.me && state.auth.me.groups[54] && state.auth.me.groups[54].membership) {
        newState.member_id = state.auth.me.groups[54].membership.id[0].value
    } else if (newState.uid) {
       console.error('This should be impossible. Your authenticated but not a member', state.auth.me)
    }
    newState.group = (state.auth.curGroup > 0) ?
        state.auth.me.groups[state.auth.curGroup] :
        false;

    return newState;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Homepage));
